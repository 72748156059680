
.ac-page-faq h1{
    font-size: 59px;
    color: #7CC6E2;
    font-size: 15rem;
    font-family: 'FbReformaNarrow-Bold';
    margin-bottom: 3rem;

}
.ac-page-faq .ac-logo-img-milka {
    width: 171px;
    height: auto;
    max-width: 171px;
    width: 42rem;
    margin-top: 4rem;
}
.ac-page-faq .ac-accordion{
    background-color: #3A2774;
    width: 90%;
    height: 60vh;
    overflow-y: auto;
    border-radius: 4rem;
    box-sizing: border-box;
    /* font-size: 26px; */
    font-size: 6.7rem;
    text-align: right;
    text-indent: -5.6rem;
    padding: 7rem;
    font-family: 'FbPekan-Regular';
}
.ac-page-faq .ac-accordion a {
    color:#fff
}
.ac-page-faq .accordion {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    position: relative;
    z-index: 8;
    margin-top: 0px;
}
.ac-page-faq .accordion__button {
    background-color: #3A2774;
    color: #fff;
    cursor: pointer;
    padding: 3rem 11rem 3rem 3rem;
    width: 100%;
    text-align: right;
    border: none;
    box-sizing: border-box;
}
.ac-page-faq .accordion__panel {
    padding: 0% 14% 3% 0%;
    animation: fadein 0.35s ease-in;
    text-indent: 0rem;
    font-size: 21px;
    font-size: 5.2rem;
}

.ac-page-faq .accordion__button:before {
    content: '+';
    height: 6.5rem;
    width: 1.5rem;
    transform: rotate(0deg);
    border-radius: 50%;
    border: 2px solid #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-right: -5rem;
    margin-left: 2.4rem;
    padding-right: 5.5rem;
    font-family: 'FbPekan-Light';
    font-size: 13rem;
    font-weight: 100;
    position: relative;
    top: 1.5rem;
    color: #7C69AC;
    padding-bottom:0.5rem;
}

.ac-page-faq .accordion__button[aria-expanded='true']::before,
.ac-page-faq .accordion__button[aria-selected='true']::before {
    content: '-';
    transform: rotate(0deg);
}
.ac-page-faq .accordion__button:after {
    display: block;
    border-right:1px dotted #fff;
    height: auto;
}
.ac-page-faq .ac-wrapper-dots{
    width: 100%;
    height: 0;
    position: relative;
}
.ac-page-faq .accordion__button[aria-expanded='true'] .ac-innerwrapper-dots{
    position: absolute;
    width: 10px;
    height: 13rem;
    top: -6rem;
    right: -8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ac-page-faq .accordion__button[aria-expanded='true'] .ac-circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 2px solid #fff;
}
.ac-page-faq .accordion__button[aria-expanded='true'] .ac-dots{
    border-right: 2px dotted #fff;
    height: 94%;
}
.ac-page-faq .accordion .accordion__item:nth-child(2) .accordion__button[aria-expanded='true'] .ac-innerwrapper-dots {
    height: 19rem;
}
.ac-page-faq .accordion .accordion__item:nth-child(3) .accordion__button[aria-expanded='true'] .ac-innerwrapper-dots {
    height: 29rem;
}
.ac-page-faq .accordion .accordion__item:nth-child(4) .accordion__button[aria-expanded='true'] .ac-innerwrapper-dots {
    height: 28rem;
}
.ac-page-faq .accordion .accordion__item:nth-child(5) .accordion__button[aria-expanded='true'] .ac-innerwrapper-dots {
    height: 29rem;
}
.ac-page-faq .accordion .accordion__item:nth-child(6) .accordion__button[aria-expanded='true'] .ac-innerwrapper-dots {
    height: 13rem;
}
.ac-page-faq .accordion .accordion__item:nth-child(4) .accordion__button[aria-expanded='true'] .ac-innerwrapper-dots,
.ac-page-faq .accordion .accordion__item:nth-child(6) .accordion__button[aria-expanded='true'] .ac-innerwrapper-dots{
    top: 0.2rem;
}