.ac-menu{
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    z-index: 2;
}
.ac-menu .ac-pos-rel-nav {
    position: relative;
    width: 100%;
    height: 70px;
}
.ac-menu .ac-pos-rel-nav nav {
    display: none;
    border: 4px solid #fff;
    width: 90%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 36%);
    border-radius: 9rem;
    position: absolute;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 3;
    background-color: #3a2774;
    text-align: center;
    height: 72rem;
}
.ac-menu .ac-pos-rel-nav nav.ac-opened {
    display: flex;
    
} 
.ac-menu .ac-pos-rel-nav nav a{
    color: #8f82b2;
    font-size: 11rem;
    position: relative;
    top: 0rem;
    text-decoration: none;
    padding: 1rem 0;
    display: block;
}
.ac-menu .ac-pos-rel-nav .ac-blur.ac-opened{
    position: absolute;
    top: -50px;
    left: 0;
    z-index: 1;
    background-color: #7c69ab5e;
    filter: blur(10px);
    width: 100%;
    height: 100vh;
    -webkit-backdrop-filter: blur(11px);
    backdrop-filter: blur(11px);
}
.ac-menu button {
     background-color: transparent;
     margin-right: 0;
     margin-left: auto;
     width: 61px;
     height: 50px;
     border: 0;
}
.ac-menu button#ac-close-nav {
    position: absolute;
    top: -64px;
    z-index: 4;
    left: 0%;
    font-size: 12.5rem;
    color: #83bee1;
    transform: rotate(45deg);
}
.ac-menu .ac-pos-rel-nav nav ul {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}
.ac-menu .ac-pos-rel-nav nav ul li:nth-child(1),
.ac-menu .ac-pos-rel-nav nav ul li:nth-child(2),
.ac-menu .ac-pos-rel-nav nav ul li:nth-child(3),
.ac-menu .ac-pos-rel-nav nav ul li:nth-child(4) {
    border-bottom: 3px solid #fff;
    height: 33%;
    display: flex;
    justify-content: center;
    align-items: center;
}