.ac-home-page .ac-logo{
    width: 150px;
    height: 62px;
    max-height: 62px;
    position: absolute;
    top: 9%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.ac-home-page .ac-festiv-milka{
    margin-top: 0vh;
}
.ac-home-page .ac-btn-home {
    width: 220px;
    height: 49px;
    width: 53rem;
    height: 12rem;
}
.ac-home-page .ac-text {
    margin-top: -4rem;
}
.ac-home-page .ac-inner-page-home{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
}
.ac-home-page .ac-popuphome{
    width: 103px;
    height: 103px;
    margin-top: 3rem;
    margin-bottom: 15rem;
}
.ac-home-page  .ac-popup{
    padding: 17% 5% 8% 5%;
}
.ac-home-page .ac-text {
    font-size: 6.2rem;
}