.ac-page-takanon h1{
    font-size: 59px;
    color: #7CC6E2;
    font-size: 15rem;
    font-family: 'FbReformaNarrow-Bold';
     margin-bottom: 4rem;

}
.ac-page-takanon h2 {
    font-family: 'FbReformaNarrow-Bold';
    color:#3A2774;
    font-size: 40px;
    font-size: 9.7rem;
    margin-top: 7rem;
}
.ac-page-takanon p{
    font-family: 'FbReformaNarrow-Medium';
    font-size: 30px;
    font-size: 7.3rem;
    line-height: 0.9;
}

.ac-page-takanon .ac-logo-img-milka {
    width: 171px;
    height: auto;
    max-width: 171px;
    width: 42rem;
    margin-top: 4rem;
}
.ac-page-takanon .ac-footer-img-milka{
    position: fixed;
    bottom: 0;
    right: 0;
}
.ac-page-takanon  .ac-text-takanon{
    background-color: #3A2774;
    width: 90%;
    padding: 3%;
    border-radius: 3rem;
    font-size: 21px;
    text-align: right;
    height: 50vh;
    overflow-y: scroll;
}