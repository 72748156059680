@font-face {
  font-family: 'FbPekan-Bold';
  src: url('./assets/fonts/FbPekan-Bold.eot');
  src: url('./assets/fonts/FbPekan-Bold.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/FbPekan-Bold.woff2') format('woff2'),
      url('./assets/fonts/FbPekan-Bold.woff') format('woff'),
      url('./assets/fonts/FbPekan-Bold.ttf') format('truetype'),
      url('./assets/fonts/FbPekan-Bold.svg#./assets/fonts/FbPekan-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FbPekan-Light';
  src: url('./assets/fonts/FbPekan-Light.eot');
  src: url('./assets/fonts/FbPekan-Light.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/FbPekan-Light.woff2') format('woff2'),
      url('./assets/fonts/FbPekan-Light.woff') format('woff'),
      url('./assets/fonts/FbPekan-Light.ttf') format('truetype'),
      url('./assets/fonts/FbPekan-Light.svg#./assets/fonts/FbPekan-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FbPekan-Black';
  src: url('./assets/fonts/FbPekan-Black.eot');
  src: url('./assets/fonts/FbPekan-Black.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/FbPekan-Black.woff2') format('woff2'),
      url('./assets/fonts/FbPekan-Black.woff') format('woff'),
      url('./assets/fonts/FbPekan-Black.ttf') format('truetype'),
      url('./assets/fonts/FbPekan-Black.svg#./assets/fonts/FbPekan-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FbPekan-Regular';
  src: url('./assets/fonts/FbPekan-Regular.eot');
  src: url('./assets/fonts/FbPekan-Regular.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/FbPekan-Regular.woff2') format('woff2'),
      url('./assets/fonts/FbPekan-Regular.woff') format('woff'),
      url('./assets/fonts/FbPekan-Regular.ttf') format('truetype'),
      url('./assets/fonts/FbPekan-Regular.svg#./assets/fonts/FbPekan-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'FbReformaNarrow-Bold';
  src: url('./assets/fonts/FbReformaNarrow-Bold.otf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FbReformaNarrow-Medium';
  src: url('./assets/fonts/FbReformaNarrow-Medium.otf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'FTahoma';
  src: url('./assets/fonts/Tahoma Bold.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Arial.ttf';
  src: url('./assets/fonts/Arial.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.ac-en-font-bold {
  font-family: 'FTahoma';
}
.ac-en-light {
  font-family: 'Arial.ttf';
}
html{
  background-color: #7c69ab;
  font-size: 1vw;
}
body {
  margin: 0;
  font-family: 'FbPekan-Regular';
  background-color: #7c69ab;
  color:#fff;
  text-align: center;
  direction:rtl;

}
body.ie{
  letter-spacing: -1px;
}
.ac-desktop {
  display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    font-size: 2rem;
}
.ac-img-responsive {
  width:100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.ac-container{
  z-index: 1;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
}
h1 {
  font-size: 8rem;
}
.ac-color-1 {
  color:#8f82b2;
}
.ac-color-2 {
  color:#83bee1;
}
.ac-text {
     /* font-size: 26px; */
     font-size: 6.7rem;
}
strong {
  font-family: 'FbPekan-Bold';
}
.ac-text .ac-text-medium {
    font-size: 24px;
    font-size: 98%;
    font-family: 'FbPekan-Light';
    margin-top: 6rem;
    display: block;
}
.ac-btn {
  background-color: #89D1EF;
  color:#543F89;
  font-family: 'FbPekan-Black';
    /* font-size: 26px; */
    font-size: 6.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9rem;
  border: 1px solid #fff;
  margin-top: 5rem;
  width: 174px;
  height: 38px;
  width: 48.5rem;
  height: 10.5rem;
  margin-bottom: 3rem;
}

.ac-btn .ac-arrow {
  /* width: 8px;
  height: 16px; */
  width: 2rem;
  height: 3.9rem;
  margin: 0 7px 0 -10px;
}
.ac-filter-blur {
  filter: blur(10px);
}
.ac-overlay {
  background-color: #7c69abdb;
  filter: blur(10px);
  width: 100%;
  height: 100%;
  backdrop-filter: blur(11px);
}
.ac-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #fff;
  border-radius: 6rem;
  background-color: #543F89;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5%;
    box-sizing: border-box;
}
.ac-popup .ac-title {
  color: #7CC6E2;
  font-size: 37px;
  font-family: 'FbReformaNarrow-Bold';
  display: block;
  line-height: 0.8;
  font-size: 10rem;
}
body .ac-vrscene .a-model,
body .ac-vrscene .a-model[aframe-injected],
body .ac-vrscene .a-enter-vr,
body .ac-vrscene .a-enter-ar  {
  display: none !important;
}

.ac-form-popup,
.ac-sms-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  transition: opacity 0.5s;
}
.ac-innerimage-popup{
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #543F89;
  border-radius: 3rem;
  padding: 5%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 68rem;
}
.ac-remove-popup {
  width: 4rem;
  height: 4rem;
  position: absolute;
  top: 7%;
  left: 5%;
}
.ac-sms-popup .ac-innerimage-popup{
  height: auto;
  padding: 5% 5% 10% 5%;
}
.ac-sms-popup .ac-popup-title{
  color: #7CC6E2;
    font-size: 49px;
    font-family: 'FbReformaNarrow-Bold';
    font-size: 13rem;
    display: block;
    margin-bottom: 3rem;
    margin-top: 10rem;
}
.ac-sms-popup .ac-popup-text{
  font-size: 24px;
  font-size: 6.3rem;
  /* font-family: 'FbPekan-Bold'; */
}
.ac-sms-popup .ac-popup-text .ac-color {
  color:#89D1EF
}
.ac-sms-popup .ac-btn {
  margin: 6rem auto;
  padding-right: 12rem;
  width: 84%;
  padding-top: 0.5rem;
}
.ac-btn .ac-en-font-bold {
  font-size: 75%;
}
.ac-sms-popup-get .ac-popup-text{
  font-family: 'FbPekan-Regular';
}
.ac-sms-popup-get .ac-en-font-bold {
  font-family: 'Arial.ttf';
}
.ac-sms-popup .ac-thankyou-sms,
.ac-sms-popup .ac-btn-second-sms{
  font-family: 'FbPekan-Black';
  /* font-size: 26px; */
  font-size: 6.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9rem;
  border: 1px solid #fff;
  margin-top: 5rem;
  width: 174px;
  height: 38px;
  width: 90%;
  height: 10.5rem;
  margin-bottom: 3rem;
  margin: auto;
  background: #3A2774;
  color: #fff;
  margin-top: 4rem;
  padding-right: unset;
}
.ac-btn-second-sms span {
  display: flex;
}
.ac-btn-second-sms.ac-not-allowed {
  color:#7C69AC;
  pointer-events: none;
}
.acccccc {
  width: 100%;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top:0;
  left:0;
}
.ie .ac-thankyou-sms,
.ie .ac-btn-second-sms{
  width: 100%;
  white-space:nowrap;
  padding: 0 7rem;
}
.ie .ac-sms-popup .ac-btn {
  width: 100%;
  white-space:nowrap
}
.ac-desktop .av-ldesk_bg{
  position: absolute;
    width: 100%;
    height: auto;
    top: 2rem;
}
.ac-desktop .av-dek_1{
  position: absolute;
  width: 32rem;
  height: auto;
  top: 12vh;
  max-width: 600px;
}
.ac-desktop .ac-dektop-form{
  position: absolute;
 bottom: 2rem;

}
.ac-desktop .ac-btn {
  width: 16rem;
  font-size: 2.2rem;
  height: 3.5rem;
  margin: 20px 18px;

}
.ac-desktop .ac-btn img{
  width: 0.6rem;
}
.ac-desktop form {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ac-desktop form label[for="text_2"] {
  display: none
}
.ac-desktop form input{
  border-radius: 6rem;
  border: 0;
  height: 3.5rem;
  padding-right: 1rem;
  color: #543f89;
}
