@font-face {
    font-family: 'FbPekan-Bold';
    src: url('../fonts/FbPekan-Bold.eot');
    src: url('../fonts/FbPekan-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FbPekan-Bold.woff2') format('woff2'),
        url('../fonts/FbPekan-Bold.woff') format('woff'),
        url('../fonts/FbPekan-Bold.ttf') format('truetype'),
        url('../fonts/FbPekan-Bold.svg#../fonts/FbPekan-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'FbPekan-Light';
    src: url('../fonts/FbPekan-Light.eot');
    src: url('../fonts/FbPekan-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FbPekan-Light.woff2') format('woff2'),
        url('../fonts/FbPekan-Light.woff') format('woff'),
        url('../fonts/FbPekan-Light.ttf') format('truetype'),
        url('../fonts/FbPekan-Light.svg#../fonts/FbPekan-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'FbPekan-Black';
    src: url('../fonts/FbPekan-Black.eot');
    src: url('../fonts/FbPekan-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FbPekan-Black.woff2') format('woff2'),
        url('../fonts/FbPekan-Black.woff') format('woff'),
        url('../fonts/FbPekan-Black.ttf') format('truetype'),
        url('../fonts/FbPekan-Black.svg#../fonts/FbPekan-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'FbPekan-Regular';
    src: url('../fonts/FbPekan-Regular.eot');
    src: url('../fonts/FbPekan-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FbPekan-Regular.woff2') format('woff2'),
        url('../fonts/FbPekan-Regular.woff') format('woff'),
        url('../fonts/FbPekan-Regular.ttf') format('truetype'),
        url('../fonts/FbPekan-Regular.svg#../fonts/FbPekan-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'FbReformaNarrow-Bold';
    src: url('../fonts/FbReformaNarrow-Bold.otf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  html{
    background-color: #7c69ab;
    font-size: 1vw;
  }
  body {
    margin: 0;
    font-family: 'FbPekan-Regular';
    background-color: #7c69ab;
    color:#fff;
    text-align: center;
    direction:rtl;
  
  }
  body.ie{
    letter-spacing: -1px;
  }
 
  .ac-img-responsive {
    width:100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  
  .ac-container{
    z-index: 1;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;
  }
  h1 {
    font-size: 8rem;
  }
  .ac-color-1 {
    color:#8f82b2;
  }
  .ac-color-2 {
    color:#83bee1;
  }
  
  .ac-page-truma .ac-logo {
    width: 171px;
    height: auto;
    max-width: 171px;
    width: 44rem;
  }
  .ac-page-truma h1,
  .ac-page-truma .ac-add-ttl {
    color: #7CC6E2;
    font-size: 90px;
    font-family: 'FbReformaNarrow-Bold';
    font-size: 21rem;
  }
  .ac-page-truma .ac-trummmmm{
    font-size: 12rem;
    display: block;
    margin-bottom: -4rem;
  }
  .ac-page-truma .ac-add-ttl { 
       display: block;
    margin-bottom: 12rem;
  }
  .ac-page-truma h2 {
    margin-top: -4rem;
    font-family: 'FbPekan-Bold';
    font-size: 46px;
    font-size: 12rem;
  }
  .ac-page-truma p {
    font-size: 26px;
    margin-top: 4rem;
    font-size: 6.3rem;
  }
  .ac-page-truma p small {
    font-size: 21px;
    font-size: 80%;
    display: block;
    line-height: 1.1;
    text-align: center;
  }
  .ac-page-truma .ac-img-1 {
    width: 46%;
    max-width: 172px;
    margin-top: 0;
    margin-left: 0;
  }
  .ac-page-truma .ac-img-1a{
    width: 46%;
    margin-top: 8rem;
  }
  .ac-page-truma .ac-img-mondelez {
    width: 25rem;
    margin: unset;
    display: inline-block;
  }
  .ac-page-truma .ac-pos-text{
    position: absolute;
    top: 119rem;
    right: 11rem;
  }
  .ac-page-truma .ac-pos-text p small {
      text-align: right;
    }
  .ac-page-truma  .ac-box-shadow{
    box-shadow: 0px -17px 26px #0000006b;
    height: 10rem;
    margin-bottom: -10rem;
    margin-top: 9rem;
    width: 100%;
  }
  .ac-page-truma .ac-img-2 {
    margin-bottom: -4rem;
  }
  .ac-page-truma .ac-img-footer {
    margin-top: 5rem;
  }
  .ac-page-truma .ac-go-tohomepage{
    width: 65px;
    height: 60px;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
  }
  p strong {
    font-family: 'FbReformaNarrow-Bold';
    color: #7CC6E2;
    font-weight: 600;
    font-size: 131%;

  }
  
 