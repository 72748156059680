.ac-page-game .ac-timer-container{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    height: 42rem;
}
/* .ac-page-game .ac-timer-container div:nth-child(1) {
 background-color: #c5c5c5;
    border-radius: 50%;
} */
 .ac-page-game .ac-timer-container div:nth-child(1){
    width: 31rem !important;
    height: 31rem !important;
    background-color: #c5c5c5;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    
 }
 .ac-page-game ::-webkit-input-placeholder { /* Chrome/Opera/Safari */ color: #fff;
 }
 .ac-page-game ::-moz-placeholder { /* Firefox 19+ */ color: #fff;
 }
 .ac-page-game :-ms-input-placeholder { /* IE 10+ */ color: #fff;
 }
 .ac-page-game :-moz-placeholder { /* Firefox 18- */ color: #fff;
 }
 .ac-page-game .ac-timer-container div:nth-child(1) svg {
    width: 28rem !important;
    height: 28rem !important;
    z-index: 2;
    position: relative;
} 
.ac-page-game .ac-timer-container div:nth-child(1) svg {
    background-color: #c5c5c5;
    border-radius: 50%;
} 
.ac-page-game .ac-timer-container div.value {
    font-size: 17rem;
    color: #4c3a7a;
    z-index: 2;
    background-color:transparent;
    font-family: 'FbPekan-Black';

}


.ac-page-game .ac-timer-container .timer-wrapper {
    display: flex;
    justify-content: center;
}
  
.ac-page-game .ac-timer-container .timer {
    font-family: 'FbPekan-Black';
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 17rem;
    color: #4c3a7a;
  
}
.ac-page-game .ac-timer-container .timer-over{
    z-index: 2;
    line-height: 0.7;
    font-size: 10rem;
}
.ac-page-game .ac-timer-container .text {
    color: #4c3a7a
}
  

  
.ac-page-game .ac-timer-container .info {
    max-width: 360px;
    margin: 40px auto 0;
    text-align: center;
    font-size: 16px;
} 

.ac-page-game .ac-inner-gamepage .ac-parashut-img-milka{
    /* position: absolute; */
    position: fixed;
    top: 11rem;
        width: 147px;
        max-width: 147px;
        width: 33rem;
}
.ac-page-game .ac-inner-gamepage .ac-footer-img-milka{
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
}
.ac-page-game .ac-inner-gamepage .ac-cloud-img-milka{
    width: 99px;
    max-width: 99px;
    width: 22rem;
    position: fixed;
    bottom: 42rem;
    left: -8rem;
}
.ac-page-game .ac-inner-gamepage .ac-content,
.ac-page-game .ac-vrscene-gamepage .ac-content {
    width: 100%;
    padding: 0 10%;
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 38px;
    font-size: 8.5rem;
    font-family: 'FbPekan-Bold';
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ac-page-game .ac-vrscene-gamepage .ac-content{
    top: unset;
    bottom: -10%;
    background-color: #7C69AC;
    width: 50%;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    border-left: 2px solid #fff;
    border-top-left-radius: 3rem;
    border-top-right-radius: 3rem;
    font-size: 5rem;
    padding: 3%;
}

.ac-page-game .ac-inner-gamepage .ac-input-wrpper,
.ac-page-game .ac-vrscene-gamepage .ac-input-wrpper{
    width: 81%;
    /* border: 1px solid #fff; */
    height: 30rem;
    margin: 3rem auto 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.ac-page-game .ac-vrscene-gamepage .ac-input-wrpper{
    height: 10rem;
}
.ac-page-game .ac-inner-gamepage .ac-input-wrpper .ac-input,
.ac-page-game .ac-vrscene-gamepage .ac-input-wrpper .ac-input{
    width: 100%;
    background: transparent;
    box-sizing: border-box;
}
.ac-page-game .ac-inner-gamepage .ac-input-wrpper input,
.ac-page-game .ac-vrscene-gamepage .ac-input-wrpper input {
    background-color: transparent;
    border: 0;
    background-color: transparent;
    border: 0;
    color: #89D1EF;
    text-align: center;
    width: 100%;
    direction: ltr;
}
.ac-page-game .ac-inner-gamepage .ac-gradient,
.ac-page-game .ac-vrscene-gamepage .ac-gradient{
    background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
    opacity: 0.13;
    width: 100%;
    height: 11rem;
}
.ac-page-game .ac-vrscene-gamepage .ac-gradient{
    height: 5rem;
}
.ac-page-game .ac-inner-gamepage .ac-gradient-top{
    transform: matrix(-1, 0, 0, -1, 0, 0);
}
.ac-page-game .ac-vrscene-gamepage .ac-gradient-top{
    display: none
}
.ac-page-game .ac-vrscene-gamepage .ac-btn {
    width: 80%;
    height: 7.5rem;
    font-size: 4.5rem;
    margin-bottom: 1rem;
    margin-top: 0rem;
}
.ac-page-game .ac-vrscene-gamepage .ac-btn .ac-arrow {
    width: 1.2rem;
    height: 2.9rem;
    margin: 0 7px 0 -10px;
}

