.ac-vrscene{
    width: 100%;
    height: 98vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
}

/* .ac-vrscene .a-model[aframe-injected] {
  display: none !important;
} */

/* .ac-vrscene .a-modal {
  display: none !important;
} */