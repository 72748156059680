.ac-page-form {
    font-family: 'FbPekan-Regular';
}
.ac-page-form .ac-milka-logo-img{
    width: 172px;
    height: 117px;
    width: 41rem;
    max-width: 172px;
    margin-top: 16px;
}
.ac-page-form .ac-title-form{
    color: #7CC6E2;
    font-size: 47px;
    font-family: 'FbReformaNarrow-Bold';
    display: block;
    line-height: 0.9;
    /* font-size: 11.5rem; */
    font-size: 19.5rem;
}
.ac-page-form  .ac-text-form{
    font-size: 26px;
    display: block;
    margin: 3rem auto 5rem;
    font-size: 6.5rem;
}
.ac-page-form .ac-form-wrapper{
    box-sizing: border-box;
    width: 78%;
}
.ac-page-form  .ac-form-wrapper input,
.ac-page-form  .ac-form-wrapper button  {
    width: 100%;
    max-height: 54px;
    height: 54px;
    border-radius: 11rem;
    height: 14rem;
    color: #543F89;
    font-size: 26px;
    font-size: 6.5rem;
    border: 0;
    margin-bottom: 2rem;
    text-indent: 5rem;
    box-sizing: border-box;
    font-family: 'FbPekan-Regular';
}
.ac-page-form .ac-form-wrapper button  {
    font-family: 'FbPekan-Black';
    border: 1px solid #fff;
}
.ac-page-form .ac-form-wrapper button#button_1 {
    margin-bottom: 4rem;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */ color: #543F89;
}
::-moz-placeholder { /* Firefox 19+ */ color: #543F89;
}
:-ms-input-placeholder { /* IE 10+ */ color: #543F89;
}
:-moz-placeholder { /* Firefox 18- */ color: #543F89;
}
.ac-page-form  .ac-form-wrapper label {
    display: none
}
.ac-page-form .checkbox label {
    width: 90%;
    text-align: right;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.ac-page-form .checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 17px;
    margin-bottom: 5px;
}
.ac-page-form .checkbox input {
    width: 25px;
    height: 22px;
    margin-left: 10px;
    margin-bottom: 0;
}
.ac-page-form .checkbox input#checkbox_2_0{
    position: relative;
    top: -0.8rem;
}
.ac-page-form .ac-form-wrapper #file_1 {
    display: none
}
.ac-page-form .ac-form-wrapper .file-custom{
    display: flex;
    width: 100%;
    max-height: 54px;
    height: 54px;
    border-radius: 11rem;
    height: 14rem;
    font-size: 26px;
    font-size: 6.5rem;
    border: 0;
    margin-bottom: 2rem;
    text-indent: 5rem;
    box-sizing: border-box;
    font-family: 'FbPekan-Regular';
    background-color: #3A2774;
    color: #7C69AC;
    justify-content: center;
    flex-direction: row-reverse;
    align-items: center;

}
.ac-page-form .ac-form-wrapper label[for="file_1"] {
    display: flex
}
.ac-page-form .ac-form-wrapper .ac-camera-img{
    width: 29px;
    height: 25px;
    max-width: 29px;
    max-height: 25px;
    width: 7rem;
    height: auto;
    margin: 0px -9rem 0 0;
}
.ac-page-form .ac-form-wrapper  #ac-btn-popup-open {
    height: auto;
    background-color: transparent;
    border: palegoldenrod;
    color: #fff;
    font-family: 'FbPekan-Regular';
    margin-bottom: -3rem;
    text-align: center;
    max-height: unset;
    text-decoration: underline;
}
.ac-page-form .ac-form-wrapper .ac-link-takanon{
    color:#fff;
    text-decoration:underline
}
.ac-page-form .ac-btn .ac-arrow {
    width: 2rem;
    height: 3.9rem;
    margin: 0 7px 0 14px;
}
.ac-page-form .ac-question{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 6.6rem;
    white-space: nowrap;
    margin-bottom: -3rem;
}
.ac-page-form .ac-form-wrapper .ac-question button {
    text-indent: unset;
    text-align: center;
    background: transparent;
    color: #fff;
    height: auto;
    line-height: normal;
    border: 0;
    font-family: 'FbPekan-Regular';
    margin-bottom: 0;
    width: auto;
}
.ac-page-form .ac-form-wrapper .form-group-image{
    margin-bottom: -5rem;
}
.ac-page-form .ac-form-wrapper .ac-innerimage-popup .ac-btns-wrapper {
    width: 63%;
    height: 25rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.ac-page-form .ac-form-wrapper .ac-get-image,
.ac-page-form .ac-form-wrapper .ac-send-image-later{
    font-size: 26px;
    display: block;
    color: #543F89;
    background-color: #89D1EF;
    border: 1px solid #fff;
    border-radius: 7rem;
    font-family: 'FbPekan-Black';
    text-align: center;
    width: 100%;
    padding: 3%;
    box-sizing: border-box;
}
.ac-form-popup.ac-image-popup.ac-hide {
    opacity: 0;
    top: -1000vw;
}

.ac-page-form .ac-form-wrapper.ac-form-wrapper-details button#button_1 {
    margin-bottom: 4rem;
    margin-top: 0.2rem;
}
.ac-page-form .ac-form-wrapper.ac-form-wrapper-invoice .ac-wrpr-hide{
    display: none;
}
.ac-page-form .ac-form-wrapper.ac-form-wrapper-invoice #ac-btn-popup-open {
    margin-bottom: 10rem;
}